
import tools from "@/common/tool"
import config from "@/common/config"
import urls from "@/common/urls"
import axios from "@/common/axios"
import store from "@/store"
export const doctorRouterBefore = (to, from, next, isAutoRoute = true) => {
  if (to.name == "meetInfo" && to.query.form == '/adminInfo') return next()
  if (store.state.common.userInfo.id) {
    next()
  } else {
    let data = tools.getStorage("userLogin", localStorage) || {};
    if (data.phone && data.passwd) {
      axios.post(urls.login.userLogin, data, {}, false).then(res => {
        tools.setStorage("userLogin", data, localStorage) || {};
        store.dispatch("setItem", { userInfo: res.data })
        store.dispatch("setItem", { userId: res.data.id });
        next()
      }).catch(() => {
        isAutoRoute ? next('/doctorLogin') : next()
      })
      return;
    }
    isAutoRoute ? next('/doctorLogin') : next()
  }
}

export const articleRouteBefore = (to, from, next) => {

  if (store.state.common.userInfo.id) next()
  else if (store.state.common.salesInfo.phone) next()
  else {
    let doctorInfo = tools.getStorage("userLogin", localStorage) || {};
    let salesInfo = tools.getStorage("saleLogin", localStorage) || {};
    if ((doctorInfo.phone && doctorInfo.passwd)) {
      doctorRouterBefore(to, from, next, false)
      return;
    }
    if (salesInfo.phone && salesInfo.code) {
      salesRouteBefore(to, from, next, false)
      return;
    }
    next()
  }
}

const jumpHome = (to, from, next) => {
  if (to.name == "home" && !tools.getStorage("jumpHome") && store.state.common.userInfo.invite_count > 0) {
    tools.setStorage("jumpHome", 1)
    next("/myMeetList")
  } else {
    next();
  }
}


export const liveRouterBefore = async (to, from, next) => {
  let signInfo = store.state.common.signInfo[to.query.id] || {};
  if (signInfo.openid) {
    if (to.name == "live") next()
    else next({
      path: `/live`,
      query: to.query
    })
    return;
  }
  // if(to.name == 'sign'){
  //   next();
  //   return;
  // }
  let url = tools.getURL(urls.live.info, tools.getSystemInfo())
  let response = await axios.get(url)
  if (response.data && response.data.openid) {
    signInfo[to.query.id] = response.data
    store.dispatch("setItem", { signInfo })
    next({
      path: `/live`,
      query: to.query
    })
  } else {
    if (to.name == 'sign') next();
    else next({
      path: `/sign`,
      query: to.query
    })
  }
}

export const commonRouterBefore = (to, from, next) => {
  next()
}


export const salesRouteBefore = (to, from, next, isAutoRoute = true) => {
  if (store.state.common.salesInfo.phone) {
    next()
  } else {
    let data = tools.getStorage("saleLogin", localStorage) || {};
    if (data.phone && data.code) {
      axios.post(urls.login.saleLogin, data, {}, false).then(res => {
        tools.setStorage("saleLogin", data, localStorage) || {};
        store.dispatch('setItem', { salesInfo: res.data })
        next()
      }).catch(() => {
        isAutoRoute ? next('/login') : next()
      })
      return;
    }
    isAutoRoute ? next('/login') : next()
  }
}